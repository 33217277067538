import React from 'react';
import config from '../../config.json';

class Footer extends React.Component {
    render() {
        return (
            <section id="footer">
                <div className="row">
                    <div className="col-sm-5 col-xs-12">
                        <div className="row">
                            <div className="col-sm-2 col-xs-12 footer-logo">
                                <img alt="logo" />
                            </div>
                            <div className="col-sm-10 col-xs-12 footer-address">
                                Boroteka<br />97-425 Kolonia Karczmy (obok wsi Jamborek)<br />Woj. Łódzkie <br />
                                <a href="https://goo.gl/maps/7BS14xdvPpsgnJS49" target="_blank" rel="noreferrer" title="Zobacz na mapie">Zobacz na Google Maps</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-7 col-xs-12">
                        <div className="row">
                            <div className="col-sm-5">
                                <ul className="no-images">
                                    <li><a href="/privacy-policy">Polityka Prywatności</a></li>
                                    <li><a href="/terms-and-conditions">Regulamin Boroteki</a></li>
                                    <li><a href="/jacuzzi-rules">Regulamin Jacuzzi</a></li>
                                </ul>
                            </div>
                            <div className="col-sm-7">
                                <ul>
                                    <li>
                                        <a href={config.facebookUrl} target="_blank" rel="noreferrer">
                                            <img src="../img/ic_facebook.svg" alt="facebook" />Facebook
                                        </a>
                                    </li>
                                    <li>
                                        <a href={config.instagramUrl} target="_blank" rel="noreferrer">
                                            <img src="../img/ic_instagram.svg" alt="instagram" />Instagram
                                        </a>
                                    </li>
                                    <li>
                                        <a href={`tel:${config.phoneNoSpaces}`}>
                                            <img src="../img/ic_phone.svg" alt="phone" />{config.phone}
                                        </a>
                                    </li>
                                    <li>
                                        <a href={`mailto:${config.email}`}>
                                            <img src="../img/ic_mail.svg" alt="email" />{config.email}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
                <div className="row">
                    <div className="col-md-8 col-sm-12 footer-copy">
                        © Boroteka 2022. Wszelkie&nbsp;prawa&nbsp;zastrzeżone.
                    </div>
                    <div className="col-md-4 col-sm-12 footer-tomo">
                        <a href="https://tomostudio.pl">Design & Code: <img alt="tomo studio" /></a>
                    </div>
                </div>
            </section>
        );
    }
}

export default Footer;
