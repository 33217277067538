import React from 'react';

const MIN_ADULTS = 1;
const MAX_ADULTS = 2;
const MIN_PETS = 0;
const MAX_PETS = 3;

class GuestsInput extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isDetailsViewVisible: false,
        };
        this.handleInputClicked = this.handleInputClicked.bind(this);
        this.expand = this.expand.bind(this);
        this.collapse = this.collapse.bind(this);
    }

    getAdultsAndChildrenPluralText = (value) => value === 1 ? "gość" : "gości";
    getBabiesPluralText = (value) => value === 1 ? "dziecko" : "dzieci";
    getPetsPluralText = (value) => value === 1 ? "zwierzak" : "zwierzaki";

    getInputText() {
        const { showPlaceholder, adultsCount, childrenCount, babiesCount, petsCount } = this.props;
        const adultsAndChildrenCount = adultsCount + childrenCount;
        if (showPlaceholder) return "";
        const results = [];
        if (adultsAndChildrenCount > 0) results.push(`${adultsAndChildrenCount} ${this.getAdultsAndChildrenPluralText(adultsAndChildrenCount)}`);
        if (babiesCount > 0) results.push(`${babiesCount} ${this.getBabiesPluralText(babiesCount)}`);
        if (petsCount > 0) results.push(`${petsCount} ${this.getPetsPluralText(petsCount)}`);
        return results.join(", ");
    }

    handleInputClicked() {
        this.setState({
            isDetailsViewVisible: !this.state.isDetailsViewVisible,
        });
    }

    render() {
        const {
            label,
            name,
            placeholder,
            error,
            maxGuestsCount,
            adultPrice,
            childPrice,
            petsPrice,
            adultsCount,
            childrenCount,
            babiesCount,
            petsCount,
            onChange,
        } = this.props;
        const { isDetailsViewVisible } = this.state;
        return (
            <div
                className={error ? "form-group error" : "form-group"}
                onClick={this.expand}>
                <label>{label}</label>
                <input
                    className="form-control"
                    name={name}
                    readonly={true}
                    placeholder={placeholder}
                    value={this.getInputText()}
                   />
                <GuestDetailsView
                    maxGuestsCount={maxGuestsCount}
                    adultPrice={adultPrice}
                    childPrice={childPrice}
                    petsPrice={petsPrice}
                    adultsCount={adultsCount}
                    childrenCount={childrenCount}
                    babiesCount={babiesCount}
                    petsCount={petsCount}
                    onChange={onChange}
                    onConfirmClick={this.collapse}
                    visible={isDetailsViewVisible} />
            </div>
        );
    }

    expand() {
        if (this.state.isDetailsViewVisible) return;
        this.setState({
            isDetailsViewVisible: true,
        });
    }

    collapse() {
        if (!this.state.isDetailsViewVisible) return;
        this.setState({
            isDetailsViewVisible: false,
        });
    }
}

const GuestDetailsView = props => {
    const {
        visible,
        maxGuestsCount,
        adultPrice,
        childPrice,
        petsPrice,
        adultsCount,
        childrenCount,
        babiesCount,
        petsCount,
        onChange,
        onConfirmClick,
    } = props;
    const onAdultsCountChange = (count) => onChange(count, childrenCount, babiesCount, petsCount);
    const onPetsCountChange = (count) => onChange(adultsCount, childrenCount, babiesCount, count);

    return (
        <div className={visible ? "guests-wrapper active" : "guests-wrapper"}>
            <div className="guests">
                <GuestsDetailsRow
                    title="Dorośli"
                    desc="2 osoby w cenie"
                    value={adultsCount}
                    minValue={MIN_ADULTS}
                    maxValue={MAX_ADULTS}
                    important={false}
                    onChange={onAdultsCountChange} />
                <GuestsDetailsRow
                    title="Zwierzaki"
                    desc="Tylko i wyłącznie małe psy"
                    badge={`+${petsPrice} zł /zw`}
                    value={petsCount}
                    minValue={MIN_PETS}
                    maxValue={MAX_PETS}
                    important={true}
                    onChange={onPetsCountChange} />
                <button className="btn btn-support1 btn-sm full-width" onClick={onConfirmClick}>Potwierdź</button>
                <div className="guests-footer">
                    Max. 2 dorosłe osoby i do 3 zwierząt.
                </div>
            </div>
        </div>
    );
}

const GuestsDetailsRow = props => {
    const { title, desc, badge, value, minValue, maxValue, important } = props;
    const canIncrement = value < maxValue;
    const canDecrement = value > minValue;
    const onPlus = () => {
        if (canIncrement) props.onChange(value + 1);
    }
    const onMinus = () => {
        if (canDecrement) props.onChange(value - 1);
    }

    return (
        <div className="guests-item">
            <div className="guests-item-text">
                <div className="guests-item-title">
                    {title}
                    <span className="guests-item-badge">{badge}</span>
                </div>
                <p className={important ? "guests-item-hint important" : "guests-item-hint"}>
                    {desc}
                </p>
            </div>
            <div className="guests-item-picker">
                <div className={canIncrement ? "picker-btn plus" : "picker-btn plus disabled"}
                    onClick={onPlus}></div>
                <div className="p1 value">{value}</div>
                <div className={canDecrement ? "picker-btn minus" : "picker-btn minus disabled"}
                    onClick={onMinus}></div>
            </div>
        </div>
    );
}

export default GuestsInput;