import React from 'react';
import config from "../../config.json";

const Header = (props) => {
    return (
        <div id="head" className="row" {...props}>
            <div className="col-10 head-logo left">
                <a href="/"><img className="logo" src="/img/logo.svg" alt="logo" /></a>
            </div>
            <div className="col-2 head-contact d-flex justify-content-end align-items-center">
                <a className="d-inline-block mx-3" href={config.instagramUrl} target="_blank" rel="noreferrer"><img className="ig-icon" src="/img/ic_instagram.svg" alt="instagram icon" /></a>
                <a className="d-inline-block mx-3" href={config.facebookUrl} target="_blank" rel="noreferrer"><img className="fb-icon" src="/img/ic_facebook.svg" alt="facebook icon" /></a>
            </div>
        </div>
    );
}

export default Header;
