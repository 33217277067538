import React from 'react';
import Document from "./Document";

export const PrivacyPolicyDocument = () => {
    return (
        <Document>
            <h1 className="document-title">Polityka<br />prywatności</h1>
            <p className="document-content">
                I. Polityka prywatności<br /><br />
                1. Administratorem danych osobowych gości jest boroteka - Rafał Stramski zamieszkały w Łomży przy ulicy Szmaragdowej 11/17<br /><br />
                2. Celem przetwarzania danych osobowych przez boroteke jest zawarcie umowy na najem krótkookresowy. Jest to niezbędne do zawarcia umowy. Ponadto, celem przetwarzania danych osobowych jest także dochodzenie ewentualnych roszczeń przez boroteke w związku z poniesioną szkodą wyrządzoną przez gościa. Przetwarzanie danych osobowych wykorzystane jest również do rozliczeń i dokumentów podatkowych.<br /><br />
                3. W trakcie procesu zawierania umowy na najem krótkookresowy (rezerwacja telefoniczna, poprzez e-mail, zameldowanie osoby) pozyskujemy, przechowujemy i przetwarzamy następujące dane osobowe: imię i nazwisko, adres, adres e-mail,numer telefonu. Podanie tych danych osobowych jest niezbędne do zawarcia umowy.<br /><br />
                4. Podstawą prawną przetwarzania danych osobowych przez boroteke  jest umowa pomiędzy Gościem a gospodarzem. Akceptując regulamin wynajmujący potwierdza zawarcie umowy wynajmu z boroteką.<br /><br />
                5. Podstawą prawną przetwarzania danych osobowych Gościa w celach marketingowych jest zgoda Gościa. Zgoda może być wycofana w każdym momencie.<br /><br />
                6. Podstawą prawną przetwarzania danych osobowych Gościa jest zapewnienie jak najwyższej jakości wynajmu krótkookresowego.<br /><br />
                7. Boroteka może przekazać dane osobowe następującym podmiotom:<br /><br />
                A. firmom świadczącym usługi wynajmu poprzez strony internetowe (Booking.com,Airbnb.pl)<br /><br />
                B. firmom świadczącym usługi księgowe i podatkowe<br /><br />
                8. Każdy Gość ma prawo dostępu do danych osobowych i ich zmiany, usunięcia lub ograniczenia przetwarzania. Ponadto każdy Gość  ma prawo do wniesienia sprzeciwu wobec przetwarzania danych osobowych do organu nadzorczego, którym jest Prezes Urzędu Ochrony Danych Osobowych.<br /><br />
                <br /><br />
                II. Formularze kontaktowe na stronie www<br /><br />
                1. Dane zbierane przez formularze kontaktowe na stronie boroteka.pl to: imię i nazwisko, numer telefonu, adres e-mail.<br /><br />
                2. Dane wpisane w formularze kontaktowe będą przetwarzane wyłącznie w celu udzielenia odpowiedzi na zadane pytanie lub rezerwację.<br /><br />
                3. Podanie wyżej wymienionych danych jest dobrowolne. Nie podanie danych wskazanych jako wymagane (e-mail) jak również brak zgody na przetwarzanie danych osobowych uniemożliwi wysłanie formularza co skutkuje brakiem odpowiedzi od boroteki<br /><br />
            </p>
        </Document>
    );
}