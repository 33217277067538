import React from 'react';
import { Link } from 'react-router-dom';
import config from '../../config.json';
import Header from "./Header";

class Lead extends React.Component {
    render() {
        return (
            <section id="lead">
                <div className="lead-main-wrapper">
                    <div className="lead-main-image" />
                    <Header />
                    <div className="lead-main">
                        <div className="lead-main-content">
                            <h1 className="lead-title">Klimatyczna <br /> turystyka <br /> w sercu Polski</h1>
                            <p className="lead-desc d-sm-block">
                                Zanurz się w głębi lasu i poszukaj <br /> inspiracji między drzewami. <br /> Twoja biblioteka wspomnień <br /> jest na wyciągnięcie ręki.
                            </p>
                            <Link to="/book" className="btn btn-support1 responsive">Rezerwuj online</Link>
                        </div>
                    </div >
                    <div className="lead-arrow">
                        <a href="#lead-intro"><img alt="slide down" /></a>
                    </div>
                </div >
                <div id="lead-intro" className="col-lg-8 col-sm-12">
                    <p>Istnieje wiele miejsc na świecie, które kojarzą się z błogim relaksem.
                        Przemierzamy dla nich setki, a nawet tysiące kilometrów. Zrobimy wszystko by choć przez chwilę odetchnąć pełną piersią.</p>

                    <p>Do tej pory obraz idealnego wypoczynku mógł kojarzyć się z egzotycznymi,najbardziej odległymi miejscami. Postanowiliśmy to zmienić i stworzyć miejsce, które pozwala uciec od zgiełku niemal w jednej chwili.</p>

                    <p className="text-center"><span>Gdzie? Tutaj</span></p>

                    <p>W pięknej Polsce, w jej samym sercu. W otoczeniu fantastycznej przyrody i malowniczego krajobrazu. Niedaleko wsi Jamborek (dokładnie Kolonia Karczmy), na skraju Parku Krajobrazowego Doliny Grabii.
                        Jeśli cenisz sobie ciszę, klimatyczne otoczenie i obecność przyrody – poczujesz się tutaj doskonale. Nasz domek znajduje się w pachnącym, sosnowym lesie. Wyraź siebie, odnajdź źródło inspiracji i rób to, co kochasz.</p>
                </div>
            </section >
        );
    }
}

export default Lead;
