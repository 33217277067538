import React from 'react';
import Slider from "react-slick";

class Gallery extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            imageSliderRef: null,
            infoSliderRef: null
        };
    }

    componentDidMount() {
        this.setState({
            imageSliderRef: this.imageSliderRef,
            infoSliderRef: this.infoSliderRef
        });
    }

    render() {
        const gotoPrev = () => this.state.infoSliderRef.slickPrev();
        const gotoNext = () => this.state.infoSliderRef.slickNext();
        const settings = {
            dots: false,
            speed: 3000,
            arrows: false,
            autoplay: true,
            autoplaySpeed: 6000,
            fade: true,
            infinite: true,
            focusOnSelect: false,
        };
        return (
            <section id="gallery">
                <Slider
                    className="gallery-image-slider"
                    ref={slider => this.imageSliderRef = slider}
                    asNavFor={this.state.infoSliderRef}
                    {...settings}>
                    <img className="img1" alt="background" />
                    <img className="img2" alt="background" />
                    <img className="img6" alt="background" />
                    <img className="img3" alt="background" />
                    <img className="img5" alt="background" />
                    <img className="img4" alt="background" />
                    <img className="img8" alt="background" />
                </Slider>
                <div className="arrows">
                    <button className="arrow arrow-prev" tabIndex={1} onClick={gotoPrev}><i /></button>
                    <button className="arrow arrow-next" onClick={gotoNext}><i /></button>
                </div>
                <Slider
                    className="gallery-info-slider"
                    ref={slider => this.infoSliderRef = slider}
                    asNavFor={this.state.imageSliderRef}
                    {...settings}>
                    <div className="gallery-info">
                        <h2>Rozrywka</h2>
                        <p>
                            Nie martw się o internet, obejrzysz swój ulubiony serial na Netflixie dzięki sprawnemu WiFi
                        </p>
                    </div>
                    <div className="gallery-info">
                        <h2>Terapia dla zmysłów</h2>
                        <p>
                            Duże okna podkręcą smak porannej kawy
                        </p>
                    </div>
                    <div className="gallery-info">
                        <h2>Kuchnia</h2>
                        <p>
                            Funkcjonalna przestrzeń zachęcająca do kulinarnych eksperymentów
                        </p>
                    </div>
                    <div className="gallery-info">
                        <h2>Salon</h2>
                        <p>
                            Weź ulubioną książkę i ciesz się chwilą – znajdziesz ich tu aż po sam sufit
                        </p>
                    </div>
                    <div className="gallery-info">
                        <h2>Sypialnia</h2>
                        <p>
                            Całkowita regeneracja i odseparowanie myśli od tego, co w tej chwili nie jest istotne do osiągnięcia błogiego relaksu.                         
                        </p>
                    </div>
                    <div className="gallery-info">
                        <h2>Taras</h2>
                        <p>
                            Kolacja w nietypowym miejscu? Proszę bardzo, zapraszamy do stołu
                        </p>
                    </div>
                    <div className="gallery-info">
                        <h2>Azyl</h2>
                        <p>
                            Obudź się tam gdzie czas staje w miejscu, a szum drzew to jedyny "hałas". Gwarantujemy, że nikt łatwo Cię nie znajdzie
                        </p>
                    </div>
                </Slider>
            </section>
        );
    }
}

export default Gallery;
