import React from 'react';
import Document from "./Document";

export const TermsAndConditionsDocument = () => {
    return (
        <Document>
            <h1 className="document-title">Regulamin<br />Boroteki</h1>
            <p className="document-content">
                1. <b>Rezerwacja i Płatności:</b> Potwierdzenie rezerwacji następuje po wpłacie zadatku w wysokości 50% ceny pobytu. Reszta kwoty powinna zostać opłacona dzień przed przyjazdem. Brak płatności daje gospodarzowi prawo do anulowania rezerwacji. Przelewy powinny być wykonane na rachunek bankowy PKO Bank Polski 66 1020 3958 0000 9702 0296 4062 Rafał Stramski, a w tytule prosimy napisać imię i nazwisko oraz wybrany termin.<br /><br />
                2. <b>Zwroty:</b> Wpłacony zadatek nie podlega zwrotowi. Można jednak przesunąć pobyt na inny termin, jeżeli anulacja nastąpi 30 dni przed przyjazdem.<br /><br />
                3. <b>Kaucja:</b> Od gości pobierana jest kaucja zwrotna w wysokości 500 zł (jest to zabezpieczenie na poczet wyrządzonych przez gości ewentualnych szkód). Kaucja jest zwracana do 3 dni roboczych od daty wymeldowania.<br /><br />
                4. <b>Zwierzęta:</b> Akceptujemy małe rasy. Gość jest odpowiedzialny za to, by zwierzę nie wchodziło na łóżko ani sofę. W przypadku nadmiernej ilości sierści, wymagającej dodatkowego sprzątania, koszty pokryte zostaną z kaucji.<br /><br />
                5. <b>Ilość gości:</b> Limit gości to dwie osoby. Zapraszanie dodatkowych osób bez wiedzy gospodarza jest kategorycznie zabronione.<br /><br />
                6. <b>Zameldowanie:</b> Gości witamy osobiście, przekazując klucze i udzielając podstawowych instrukcji dotyczących korzystania z obiektu, takich jak np.  zasady korzystania z kominka czy jacuzzi.<br /><br />
                7. <b>Odpowiedzialność za szkody:</b> Wynajmujący jest odpowiedzialny za wszelkie szkody w domku. W przypadku wyrządzenia szkody/usterki, gość zobowiązany jest do niezwłocznego powiadomienia o tym gospodarza.<br /><br />
                8. <b>Minimalny czas wynajmu:</b> Domek wynajmowany jest na min. 2 doby.<br /><br />
                9. <b>Ceny specjalne:</b> Prosimy zauważyć, że w okresach "długich weekendów", świąt i sylwestra mogą obowiązywać specjalne stawki. Aby uzyskać szczegółowe informacje, zachęcamy do kontaktu telefonicznego.<br /><br />
                10. <b>Czas doby:</b> Doba obowiązuje od godziny 15:00 w dniu przyjazdu do godziny 11:00 w dniu wyjazdu.<br /><br />
                11. <b>Porządek i higiena:</b> Prosimy o przestrzeganie podstawowych zasad porządku i kultury. Dbaj o nasz leśny kącik jak o swój własny dom.<br /><br />
                12. <b>Zakazy:</b> W całym domku obowiązuje bezwzględny zakaz palenia. Zakazane jest również rozpalanie ognisk oraz używanie otwartego ognia.<br /><br />
                13. <b>Użytkowanie komercyjne i reklamowe:</b> Boroteka nie może być wykorzystywana w celach komercyjnych i reklamowych bez zgody gospodarza.<br /><br />
                14. <b>Zastrzeżenia gospodarza:</b> Gospodarz zastrzega sobie prawo do odwiedzenia domu, jeżeli jest to konieczne do jego utrzymania lub jeśli podejrzewa, że regulamin nie jest przestrzegany.<br /><br />
            </p>
        </Document>
    );
}