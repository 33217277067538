import React from 'react';

const CheckBox = props => {
    return (
        <label className={props.error ? "form-checkbox error" : "form-checkbox"}>
            <input
                type="checkbox"
                name={props.name}
                checked={props.checked}
                onChange={props.onChange}/>
            <div className="checkmark"></div>
            <label>{props.children}</label>
        </label>
    );
};

export default CheckBox;