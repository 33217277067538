import React from 'react';
import DateRangeInput from './form/DateRangeInput';
import GuestsInput from './form/GuestInput';
import Price from './form/Price';
import { errors } from "./errors";
import Moment from "moment";
import CheckBox from "./form/CheckBox";

const BASE_PRICE_IN_SEASON_WEEKDAYS = 650.00
const BASE_PRICE_IN_SEASON_WEEKEND = 650.00
const BASE_PRICE_OFF_SEASON_WEEKDAYS = 550.00
const BASE_PRICE_OFF_SEASON_WEEKEND = 650.00
const PRICE_JACUZZI = 100.00
const ADDITIONAL_GUEST_PRICE = 0
const PETS_PRICE = 100
const MAX_GUESTS = 2

class Step1 extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            validate: false,
        }
        this.validate = this.validate.bind(this);
        this.handleGuestsChange = this.handleGuestsChange.bind(this);
        this.handleDatesChange = this.handleDatesChange.bind(this);
        this.handleJacuzziChange = this.handleJacuzziChange.bind(this);
        this.handleNextClick = this.handleNextClick.bind(this);
    }

    validate() {
        const { checkIn, checkOut, adultsCount, childrenCount, babiesCount } = this.props;
        const fields = {};
        fields["dates"] = !checkIn || !checkOut;
        fields["guests"] = adultsCount + childrenCount + babiesCount === 0;
        if (Object.values(fields)?.some(it => it)) {
            return {
                message: errors.MISSING_REQUIRED_FIELDS,
                fields: fields,
            }
        }
        if (checkIn && checkOut && Moment(checkOut).diff(Moment(checkIn), 'days') < 2) {
            fields["dates"] = true;
            return {
                message: errors.DATE_RANGE_TOO_SMALL,
                fields: fields,
            }
        }
        if (adultsCount === 0) {
            fields["guests"] = true;
            return {
                message: errors.AT_LEAST_ONE_ADULT,
                fields: fields,
            }
        }
        return null;
    }

    handleGuestsChange(adults, children, babies, pets) {
        const { checkIn, checkOut, jacuzzi } = this.props;
        this.props.onGuestsChange(adults, children, babies, pets)
        this.calculatePrice(checkIn, checkOut, adults, children, pets, jacuzzi)
    }

    handleDatesChange(checkIn, checkOut) {
        const { adultsCount, childrenCount, petsCount, jacuzzi } = this.props;
        this.props.onDatesChange(checkIn, checkOut)
        this.calculatePrice(checkIn, checkOut, adultsCount, childrenCount, petsCount, jacuzzi)
    }

    handleJacuzziChange(event) {
        const checked = event.target.checked;
        const { checkIn, checkOut, adultsCount, childrenCount, petsCount } = this.props;
        this.props.onJacuzziChange(checked)
        this.calculatePrice(checkIn, checkOut, adultsCount, childrenCount, petsCount, checked)
    }

    handleNextClick() {
        if (!this.validate()) {
            this.props.onNextClick()
        } else {
            this.setState({ validate: true })
        }
    }

    calculatePrice(checkIn, checkOut, adults, children, pets, jacuzzi) {
        if (adults === 0) return;
        const checkInMoment = Moment(checkIn);
        const checkOutMoment = Moment(checkOut);

        let price = 0;
        let date = checkInMoment;
        while (date < checkOutMoment) {
            const day = (date.day());
            const month = (date.month() + 1)
            const isWeekend = day === 5 || day === 6;
            const isSeason = month === 5 || month === 6 || month === 7 || month === 8 || month === 9;
            const basePrice = isSeason ? (isWeekend ? BASE_PRICE_IN_SEASON_WEEKEND : BASE_PRICE_IN_SEASON_WEEKDAYS) : (isWeekend ? BASE_PRICE_OFF_SEASON_WEEKEND : BASE_PRICE_OFF_SEASON_WEEKDAYS);
            price += basePrice;
            if (jacuzzi) {
                price += PRICE_JACUZZI
            }
            date = date.add({ days: 1 })
        }
        price += pets * PETS_PRICE;

        this.props.onPriceChange(price !== 0 ? price : null)
    }

    render() {
        const {
            unavailableDays,
            checkIn = Date(),
            checkOut = Date(),
            adultsCount,
            childrenCount,
            babiesCount,
            petsCount,
            jacuzzi,
            price,
            loading,
        } = this.props;

        const error = this.state.validate ? this.validate() : null;

        return (
            <div className="form">
                <div className="form-wrapper">
                    <div className="booking-logo-form">
                        <a href="/"><img alt="logo" /></a>
                    </div>
                    <p className="form-step">KROK 1/3</p>
                    <h1 className="form-title">Wybierz datę odwiedzin</h1>
                    <p className="form-hint">
                        Zerknij w kalendarz i zaplanuj odpoczynek przy akompaniamencie szumu lasu.
                    </p>
                    {error &&
                        <p className="form-error">{error.message}</p>
                    }
                    <DateRangeInput
                        unavailableDays={unavailableDays}
                        labelFrom="Zameldowanie"
                        labelTo="Wymeldowanie"
                        placeholderFrom="Dodaj datę"
                        placeholderTo="Dodaj datę"
                        from={checkIn}
                        to={checkOut}
                        loading={loading}
                        error={error?.fields["dates"]}
                        onChange={this.handleDatesChange}
                    />
                    <GuestsInput
                        label="Goście"
                        name="guests"
                        placeholder="Dodaj gości"
                        showPlaceholder={adultsCount + petsCount === 0}
                        error={error?.fields["guests"]}
                        adultsCount={adultsCount}
                        childrenCount={childrenCount}
                        babiesCount={babiesCount}
                        petsCount={petsCount}
                        maxGuestsCount={MAX_GUESTS}
                        adultPrice={ADDITIONAL_GUEST_PRICE}
                        childPrice={ADDITIONAL_GUEST_PRICE}
                        petsPrice={PETS_PRICE}
                        onChange={this.handleGuestsChange} />
                    <CheckBox
                        name="jacuzzi"
                        checked={jacuzzi}
                        onChange={this.handleJacuzziChange}>
                        Korzystanie z jacuzzi przez cały okres pobytu
                    </CheckBox>
                    {price &&
                        <Price value={price} />
                    }
                </div>
                <div className="buttons-wrapper">
                    <button className="btn btn-support1 full-width" onClick={this.handleNextClick}>
                        Przejdź dalej
                    </button>
                </div>
            </div>
        );
    }
}

export default Step1;