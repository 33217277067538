import React from 'react';
import Document from "./Document";

export const JacuzziRulesDocument = () => {
    return (
        <Document>
            <h1 className="document-title">Regulamin<br />Jacuzzi</h1>
            <p className="document-content">
                <b>REGULAMIN KORZYSTANIA Z WANNY JACUZZI</b><br /><br />
                1. Jacuzzi jest wyposażone w pompę ciepła, która zapewnia ogrzewanie wody w temperaturach zewnętrznych nie niższych niż 15 stopni Celsiusza. W tym trybie jacuzzi jest w pełni bezobsługowe.<br /><br />
                2. Podczas niższych temperatur zewnętrznych, gdy temperatura w dzień spada poniżej 14 stopni Celsjusza, w okresie jesienno-zimowym, ogrzewanie jacuzzi odbywa się za pomocą zewnętrznego pieca na drewno, podobnie jak to ma miejsce w balianach ogrodowych. <b>W tym kontekście, użytkownik jest zobowiązany do samodzielnego podgrzewania wody w jacuzzi, poprzez rozpalanie drewna w piecu.</b><br /><br />
                3. Przed skorzystaniem z wanny jacuzzi, należy dokładnie zapoznać się z tym Regulaminem oraz ewentualnymi przeciwwskazaniami do korzystania z kąpieli.<br /><br />
                <b>4. Korzystanie z jacuzzi jest możliwe tylko po uprzednim skorzystaniu z prysznica i jest zabronione pod wpływem alkoholu lub innych środków odurzających.</b><br /><br />
                5. Użytkownik korzysta z jacuzzi na własne ryzyko i ponosi pełną odpowiedzialność za wszelkie wypadki spowodowane nieprzestrzeganiem regulaminu.<br /><br />
                6. Osoba, która celowo lub przypadkowo zanieczyści jacuzzi, będzie obciążona kosztami wymiany wody.<br /><br />
                <b>7. Pokrywa jacuzzi musi być zawsze nałożona, gdy wanna nie jest używana, co pozwala zminimalizować straty ciepła. Przed skorzystaniem z jacuzzi, odkładaj pokrywę w wyznaczonym miejscu, a po skończonej kąpieli, niezwłocznie przykryj jacuzzi.</b><br /><br />
                <b>8. Pomimo stałej opłaty za korzystanie z jacuzzi, zaniedbanie obowiązku przykrywania go po użyciu może prowadzić do zwiększonego zużycia energii. Prosimy o odpowiedzialne korzystanie z jacuzzi - wszelkie nadmierne zużycie zasobów, zidentyfikowane przez nasze systemy monitorujące, może skutkować dodatkowymi kosztami, które zostaną potrącone z Twojej kaucji</b><br /><br />
                <br /><br />
                <b>INSTRUKCJA KORZYSTANIA Z WANNY JACUZZI</b><br /><br />
                1. Wejście i wyjście do wanny powinno odbywać się z zachowaniem szczególnej ostrożności.<br /><br />
                2. Zaleca się, aby pobyt w wannie nie był dłuższy niż 15 minut (występuje podwyższony poziom środka dezynfekującego).<br /><br />
                3. Kąpiel w wannie powinna odbywać się wyłącznie w pozycji siedzącej.<br /><br />
                4. Wszelkie nieprawidłowości należy niezwłocznie zgłosić Właścicielowi obiektu.<br /><br />
                <br /><br />
                <b>OSOBOM PRZEBYWAJĄCYM W WANNIE JACUZZI ZABRANIA SIĘ:</b><br /><br />
                1. Powodowania sytuacji, które mogą zagrażać bezpieczeństwu własnemu lub innych osób korzystających z wanny.<br /><br />
                2. Wpychania osób do wanny.<br /><br />
                3. Przynoszenia do wanny jakichkolwiek przedmiotów lub substancji chemicznych, np. szamponów, mydła, olejków, soli kąpielowych itp.<br /><br />
                4. Manipulacji przy dyszach i pompie ciepła.<br /><br />
                <br /><br />
                <b>UWAGA! Pamiętaj, że długotrwałe korzystanie z wanny jacuzzi może wywołać przegrzanie organizmu. Symptomami mogą być: drgawki, nagły spadek ciśnienia krwi, zawroty głowy, zasłabnięcie. W przypadku jakichkolwiek niepokojących objawów, natychmiast przerwij korzystanie z jacuzzi.</b>
            </p>
        </Document>
    );
}