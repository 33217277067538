import React from 'react';

class Features extends React.Component {
    render() {
        return (
            <section id="features">
                <div className="row">
                    <div className="col-lg-4">
                        <div className="row features-text">
                            Twoja biblioteka wspomnień jest na wyciągnięcie ręki
                        </div>
                    </div>
                    <div className="col-lg-8 col-sm-12 features-list">
                        <div className="row align-items-center">
                            <div className="col-lg-7 col-sm-5 col-xs-12">
                                <div className="feature-desc-box">
                                    <h2 className="feature-title">Żyj chwilą</h2>
                                    <p className="feature-desc">
                                        Boroteka to miejsce, gdzie stworzysz nowe, cenne wspomnienia. Oferujemy Ci niezwykłą przestrzeń na realizację planów lub ich tworzenie. </p>
                                    <p className="feature-desc">
                                        Jeśli kochasz to, co piękne i prawdziwe – odnajdziesz się tutaj w jednej chwili. Nie obawiaj się o prywatność. Będziesz miał jej naprawdę dużo.
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-5 col-sm-7 col-xs-12">
                                <img className="feature-image feature-1" alt="sypialnia w domku" />
                            </div>
                        </div>
                        <div className="row align-items-center">
                            <div className="col-md-5 col-sm-7 col-xs-12">
                                <img className="feature-image feature-2" alt="sypialnia w domku" />
                            </div>
                            <div className="col-md-7 col-sm-5 col-xs-12">
                                <div className="feature-desc-box feature-desc-box--white">
                                    <h2 className="feature-title">Miłość do świata</h2>
                                    <p className="feature-desc">
                                        Miłość do przyrody to miłość do nas samych. Zabierz bliskich i cieszcie się swoją obecnością. Duża działka pozwoli wybiegać się czworonożnym przyjaciołom lub pograć w piłkę, jeśli tylko tego potrzebujesz.
                                    </p>
                                    <p className="feature-desc">
                                        Nocą możesz obserwować gwiazdy leżąc wygodnie na ziemi. Usiądź przed kominkiem i  zrelaksuj się – to Ty decydujesz jak odpoczywasz.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="row align-items-center">
                            <div className="col-lg-7 col-sm-5 ol-xs-12">
                                <div className="feature-desc-box">
                                    <h2 className="feature-title">Unikalny design</h2>
                                    <p className="feature-desc">
                                        Nasz domek to nie tylko okno na świat. Znajdziesz tu wszystko czego tylko potrzebujesz. Elementy drewniane podkreślają piękno tego miejsca i wspaniale komponują się z otoczeniem.
                                    </p>
                                    <p className="feature-desc">
                                        Wygodne łóżka, miękkie koce i minimalistyczne akcenty wpiszą się w gust najbardziej wymagających osób. Szczególnie, jeśli romantyzm jest wpisany w Twoją osobowość. Domek idealnie sprawdzi się dla par.
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-5 col-sm-7 col-xs-12">
                                <img className="feature-image feature-3" alt="sypialnia w domku" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Features;
