import React from 'react';
import Moment from "moment";

import 'react-date-range/dist/styles.css';
import '../../../scss/booking-date-picker.scss';
import DateRange from "react-date-range/dist/components/DateRange";
import { pl } from "react-date-range/dist/locale";
import { format, isSameDay } from 'date-fns';

class DateRangeInput extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isPickerVisible: false,
        };
        this.handleSelect = this.handleSelect.bind(this);
        this.expand = this.expand.bind(this);
        this.collapse = this.collapse.bind(this);
        this.renderDay = this.renderDay.bind(this);
        this.isBeforeUnavailablePeriod = this.isBeforeUnavailablePeriod.bind(this);
        this.isAfterUnavailablePeriod = this.isAfterUnavailablePeriod.bind(this);
    }

    handleSelect(ranges) {
        const checkIn = ranges.selection.startDate;
        const checkOut = ranges.selection.endDate;
        this.props.onChange(checkIn, checkOut);
    }

    expand() {
        if (this.state.isPickerVisible) return;
        this.setState({
            isPickerVisible: true,
        });
    }

    collapse() {
        if (!this.state.isPickerVisible) return;
        this.setState({
            isPickerVisible: false,
        });
    }

    isBeforeUnavailablePeriod(day, unavailableDays) {
        if (unavailableDays.find((it) => isSameDay(it, day))) {
            return false;
        }
        const tomorrow = Moment(day).add(1, "days").toDate();
        return unavailableDays.find((it) => isSameDay(it, tomorrow)) !== undefined;

    }

    isAfterUnavailablePeriod(day, unavailableDays) {
        if (unavailableDays.find((it) => isSameDay(it, day))) {
            return false;
        }
        const yesterday = Moment(day).subtract(1, "days").toDate();
        return unavailableDays.find((it) => isSameDay(it, yesterday)) !== undefined;
    }

    renderDay(day, unavailableDays) {
        if (unavailableDays == null) {
            return (
                <div>
                    <span>{format(day, "d")}</span>
                </div>
            )
        }
        const isBefore = this.isBeforeUnavailablePeriod(day, unavailableDays);
        const isAfter = this.isAfterUnavailablePeriod(day, unavailableDays);
        if (isBefore && isAfter) {
            return (
                <div className="rdrAfterDisabled rdrBeforeDisabled">
                    <span>{format(day, "d")}</span>
                </div>
            )
        }
        if (isBefore) {
            return (
                <div className="rdrBeforeDisabled">
                    <span>{format(day, "d")}</span>
                </div>
            )
        }
        if (isAfter) {
            return (
                <div className="rdrAfterDisabled">
                    <span>{format(day, "d")}</span>
                </div>
            )
        }
        return (
            <div>
                <span>{format(day, "d")}</span>
            </div>
        )
    }

    render() {
        const { isPickerVisible } = this.state;
        const {
            unavailableDays,
            from,
            to,
            labelFrom,
            labelTo,
            placeholderFrom,
            placeholderTo,
            error,
        } = this.props;
        const range = {
            startDate: from || new Date(),
            endDate: to || new Date(),
            key: 'selection',
            showDateDisplay: false,
        }
        const dayContentRenderer = (day) => this.renderDay(day, unavailableDays);
        return (
            <div className={error ? "dates error" : "dates border-right"}>
                <div className={error ? "form-group border-right error" : "form-group border-right"}
                    onClick={this.expand}>
                    <label>{labelFrom}</label>
                    <input
                        className="form-control"
                        type="text"
                        disabled={false}
                        readonly={true}
                        placeholder={placeholderFrom}
                        value={(from && Moment(from).format("DD.MM.yyyy")) || ''} 
                     
                        />
                </div>
                <div className={error ? "form-group error" : "form-group"}
                    onClick={this.expand}>
                    <label>{labelTo}</label>
                    <input
                        className="form-control"
                        type="text"
                        disabled={false}
                        readonly={true}
                        placeholder={placeholderTo}
                        value={(to && Moment(to).format("DD.MM.yyyy")) || ''} />
                </div>
                <div className={isPickerVisible ? "calendar-wrapper active" : "calendar-wrapper"}>
                    <div className="calendar">
                        <DateRange
                            ranges={[range]}
                            locale={pl}
                            disabledDates={unavailableDays || []}
                            minDate={new Date()}
                            dayContentRenderer={dayContentRenderer}
                            onChange={this.handleSelect} />
                        <div className="dates-button">
                            <button className="btn btn-support1 btn-sm full-width" onClick={this.collapse}>Potwierdź</button>
                        </div>
                        <div className="dates-footer">
                            Minimalny pobyt: 2 noce
                        </div>
                    </div>
                </div>
            </div>
        );
    }


}

export default DateRangeInput;