import React from 'react';
import { Link } from 'react-router-dom';

class Features extends React.Component {
    render() {
        return (
            <section id="pricing">
                <div className="pricing-cards">
                    <div className="row">
                        <div className="col-lg-5 col-sm-6 pricing-card">
                            <h2>Sezon niski</h2>
                            <p className="pricing-info">Październik - Kwiecień</p>
                            <div className="row">
                                <div className="col-lg-6 pricing-card--small">
                                    <div className="pricing-price">
                                        <p>550&nbsp;zł</p>
                                        <p className="pricing-info">Cena w tygodniu</p>
                                    </div>
                                </div>
                                <div className="col-lg-6 pricing-card--small">
                                    <div className="pricing-price">
                                        <p>650&nbsp;zł</p>
                                        <p className="pricing-info">Cena w weekend</p>
                                    </div>
                                </div>
                            </div>
                            <p className="pricing-info">/doba 2 osoby</p>
                        </div>
                        <div className="col-lg-3 col-sm-6 pricing-card">
                            <h2>Sezon wysoki</h2>
                            <p className="pricing-info">Maj - Wrzesień</p>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="pricing-price">
                                        <p>650&nbsp;zł</p>
                                        <p className="pricing-info">Każdy dzień tygodnia</p>
                                    </div>
                                </div>
                            </div>
                            <p className="pricing-info">/doba 2 osoby</p>
                        </div>
                        <div className="col-lg-4 col-sm-6 pricing-card last">
                            <h2>Dopłaty</h2>
                            <div className="pricing-text">
                                <p className="p1">
                                    Zwierzaki
                                    <span className="pricing-info2"> -&nbsp;100&nbsp;zł/pobyt</span>
                                </p>
                            </div>
                            <div className="pricing-text">
                                <p className="p1">
                                    Kaucja zwrotna
                                    <span className="pricing-info2"> -&nbsp;500&nbsp;zł</span>
                                </p>
                                <p className="p4 pricing-info">
                                    Najlepiej poczują się tutaj dorośli, to taka terapia dla wszystkich zmysłów, regeneracja i odseparowanie myśli od tego, co w tej chwili nie jest istotne do osiągnięcia błogiego relaksu. Zostaw dzieci pod opieką zaufanych opiekunów i zakochaj się w tym miejscu od pierwszego wejrzenia.                                </p>
                            </div>
                        </div>
                        <div className="col-lg-12 col-sm-6 pricing-hints">
                            <div className="row d-flex alig-items-center">
                                <div className="col-lg-3 col-sm-12 align-content-center">
                                    <div className="pricing-hint hint-1">
                                        50m2
                                    </div>
                                </div>
                                <div className="col-lg-3 col-sm-12">
                                    <div className="pricing-hint hint-2">
                                        posesja w pełni ogrodzona
                                    </div>
                                </div>
                                <div className="col-lg-3 col-sm-12">
                                    <div className="pricing-hint hint-3">
                                        max 2 osoby
                                    </div>
                                </div>
                                <div className="col-lg-3 col-sm-12">
                                    <div className="pricing-hint hint-5">
                                        jeśli chcecie zabrać zwierzaka - zapytajcie
                                    </div>
                                </div>
                            </div>
                            <div className="row d-flex align-items-center">
                                <div className="col-lg-3 col-sm-12">
                                    <div className="pricing-hint hint-8">
                                        bezpłatne Wi-Fi dostępne
                                    </div>
                                </div>
                                <div className="col-lg-3 col-sm-12">
                                    <div className="pricing-hint hint-4">
                                        minimalny okres pobytu: 2 doby
                                    </div>
                                </div>
                                <div className="col-lg-3 col-sm-12">
                                    <div className="pricing-hint hint-6">
                                        Święta i długie weekendy wyceniamy indywidualnie
                                    </div>
                                </div>
                                <div className="col-lg-3 col-sm-12">
                                    <div className="pricing-hint hint-7">
                                        Zapoznaj się
                                        z <a href="/terms-and-conditions">Regulaminem Wynajmu</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="booking">
                    <h1 className="booking-title">Jesteśmy do Twojej dyspozycji.<br /> Choćby zaraz.</h1>
                    <p className="booking-desc d-md-block">
                        Magia tego miejsca jest nam znana i zrobiliśmy wszystko, by przybliżyć Tobie wszystkie zalety tego miejsca. Jeśli masz jakiekolwiek pytania, sugestie lub czegokolwiek od nas oczekujesz – skontaktuj się z nami i powiedz, jak możemy Tobie pomóc.
                    </p>
                    <Link to="/book/1" className="btn btn-support1 responsive">Rezerwuj online</Link>
                </div>
            </section >
        );
    }
}

export default Features;
